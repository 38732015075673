var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jobhunting"},[_c('div',{staticClass:"job-position"},[_c('div',{staticClass:"position-content"},[_c('div',{staticClass:"position-tab-title"},[_vm._l((_vm.searcList),function(Sitem,Sindex){return _c('div',{key:Sindex,staticClass:"position-tab-a"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(Sitem.name)+"：")]),_c('div',{staticClass:"info flex1"},_vm._l((Sitem.data),function(Szitem,Szindex){return _c('div',{key:Szindex,class:[
								'info-a',
								Sitem.clcik == Szitem.value ? 'info-Select' : '',
							],on:{"click":function($event){return _vm.SelectTap(Sindex, Szindex)}}},[_vm._v(" "+_vm._s(Szitem.name)+" ")])}),0)])}),_c('div',{staticClass:"position-tab-a flex1"},[_c('div',{staticClass:"title"},[_vm._v("年龄：")]),(!_vm.isfieldchage)?_c('div',{staticClass:"info flex1"},[_c('div',{staticClass:"info-a info-Select"},[_vm._v("不限")]),_c('div',{staticClass:"info-a",on:{"click":function($event){_vm.isfieldchage = true}}},[_vm._v("选取区间")])]):_vm._e(),(_vm.isfieldchage)?_c('div',{staticClass:"info flex1"},[_c('el-input-number',{attrs:{"min":18,"max":_vm.field2},model:{value:(_vm.field1),callback:function ($$v) {_vm.field1=$$v},expression:"field1"}}),_c('span',[_vm._v("至")]),_c('el-input-number',{attrs:{"min":_vm.field1,"max":80},model:{value:(_vm.field2),callback:function ($$v) {_vm.field2=$$v},expression:"field2"}}),_c('el-button',{staticStyle:{"margin-left":"40px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.yearselect()}}},[_vm._v("确认")]),_c('el-button',{staticStyle:{"margin-left":"20px"},attrs:{"type":"info"},on:{"click":function($event){(_vm.stateyear = ''),
							(_vm.endyear = ''),
							(_vm.isfieldchage = false),
							_vm.GetList()}}},[_vm._v("不限")])],1):_vm._e()]),_c('div',{staticClass:"position-tab-a flex1"},[_c('div',{staticClass:"title"},[_vm._v("需求岗位：")]),_c('div',{staticClass:"info flex1"},[_c('el-input',{model:{value:(_vm.key),callback:function ($$v) {_vm.key=$$v},expression:"key"}}),_c('el-button',{staticStyle:{"margin-left":"40px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.yearselect()}}},[_vm._v("搜索")])],1)])],2),_c('div',{staticClass:"center"},[_c('div',{staticClass:"position-tab"},[_c('div',{staticClass:"position-tab-content"},[_c('div',{staticClass:"resumelist"},_vm._l((_vm.PerJobList),function(resumeItem,resume){return _c('div',{key:resume,staticClass:"resume flex2"},[_c('div',{staticClass:"resumeLeft flexc",on:{"click":function($event){return _vm.talk('/resumedetail', {id: resumeItem.peR_ID,uid: _vm.user.coM_ID,
										zph: '',})}}},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":resumeItem.peR_IMG_PATH
													? _vm.setuserlogo(resumeItem.peR_IMG_PATH)
													: require('@/assets/img/tx.png'),"alt":""}})]),_c('div',{staticClass:"personal"},[_c('div',{staticClass:"name flex1"},[_c('div',[_vm._v(" "+_vm._s(resumeItem.peR_NAME ? _vm.utils.formatName(resumeItem.peR_NAME) : resumeItem.peR_ACCOUNT ? _vm.utils.formatName(resumeItem.peR_ACCOUNT) : "神秘人")+" ")]),_c('div',{staticClass:"tip"},[(resumeItem.peR_GENDER)?_c('span',[_vm._v(_vm._s(resumeItem.peR_GENDER))]):_vm._e(),(resumeItem.peR_GENDER)?_c('span',[_vm._v("|")]):_vm._e(),(resumeItem.age)?_c('span',[_vm._v(_vm._s(_vm.utils.getAge(resumeItem.peR_BIRTHDAY))+"岁")]):_vm._e(),(resumeItem.age)?_c('span',[_vm._v("|")]):_vm._e(),(resumeItem.peR_WORK_YEARS)?_c('span',[_vm._v(_vm._s(resumeItem.peR_WORK_YEARS)+"年经验")]):_vm._e(),(resumeItem.peR_WORK_YEARS)?_c('span',[_vm._v("|")]):_vm._e(),_c('span',[_vm._v(_vm._s(resumeItem.per_edu))]),(resumeItem.per_edu)?_c('span',[_vm._v("|")]):_vm._e()]),_c('div',{staticStyle:{"font-size":"14px","color":"rgb(137, 137, 137)"}},[_c('span',[_vm._v("更新时间:"+_vm._s(resumeItem.peR_UPDATE_DATE.substring(0,11)))])])]),_c('div',{staticClass:"flex1",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"hot_move"},[_c('span',[_vm._v(_vm._s(resumeItem.workname))])])])])])]),_c('div',{staticClass:"resumeRight"},[_c('div',{staticClass:"talk1 flexc",on:{"click":function($event){return _vm.sendInvitation(resumeItem.peR_ID)}}},[_vm._v(" 面试邀请 ")]),_c('div',{staticClass:"talk flexc",on:{"click":function($event){return _vm.talk('/resumedetail', {
											id: resumeItem.peR_ID,
											uid: _vm.user.coM_ID,
											zph: '',
										})}}},[_vm._v(" 查看简历 ")])])])}),0),_c('div',{staticStyle:{"text-align":"center","margin-bottom":"20px"}},[_c('el-pagination',{attrs:{"hide-on-single-page":"","current-page":_vm.form.pageNumber,"page-sizes":[10, 20, 30, 40],"page-size":_vm.form.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)]),(_vm.PerJobList.length < 1)?_c('el-empty',{staticStyle:{"background":"#fff"},attrs:{"image-size":200}}):_vm._e()],1),_vm._m(0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recent"},[_c('div',{staticClass:"recentBrowsing"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("最近浏览")])])])])
}]

export { render, staticRenderFns }