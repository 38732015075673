<template>
	<div class="jobhunting">
		<div class="job-position">
			<div class="position-content">
				<div class="position-tab-title">
					<div class="position-tab-a" v-for="(Sitem, Sindex) in searcList" :key="Sindex">
						<div class="title">{{ Sitem.name }}：</div>
						<div class="info flex1">
							<div v-for="(Szitem, Szindex) in Sitem.data" :key="Szindex"
								@click="SelectTap(Sindex, Szindex)" :class="[
									'info-a',
									Sitem.clcik == Szitem.value ? 'info-Select' : '',
								]">
								{{ Szitem.name }}
							</div>
						</div>
					</div>
					<div class="position-tab-a flex1">
						<div class="title">年龄：</div>
						<div class="info flex1" v-if="!isfieldchage">
							<div class="info-a info-Select">不限</div>
							<div class="info-a" @click="isfieldchage = true">选取区间</div>
						</div>
						<div class="info flex1" v-if="isfieldchage">
							<el-input-number v-model="field1" :min="18" :max="field2"></el-input-number>
							<span>至</span>
							<el-input-number v-model="field2" :min="field1" :max="80"></el-input-number>
							<el-button style="margin-left: 40px" @click="yearselect()" type="primary">确认</el-button>
							<el-button style="margin-left: 20px" @click="
								(stateyear = ''),
								(endyear = ''),
								(isfieldchage = false),
								GetList()
							" type="info">不限</el-button>
						</div>
					</div>
					<div class="position-tab-a flex1">
						<div class="title">需求岗位：</div>
						<div class="info flex1">
							<el-input v-model="key"></el-input>
							<el-button style="margin-left: 40px" @click="yearselect()" type="primary">搜索</el-button>
						</div>
					</div>
				</div>
				<div class="center">
					<div class="position-tab">
						<div class="position-tab-content">
							<div class="resumelist">
								<div class="resume flex2" v-for="(resumeItem, resume) in PerJobList" :key="resume">
									<div class="resumeLeft flexc" @click="
										talk('/resumedetail', {id: resumeItem.peR_ID,uid: user.coM_ID,
											zph: '',})
									">
										<div class="left">
											<div class="pic">
												<img :src="
													resumeItem.peR_IMG_PATH
														? setuserlogo(resumeItem.peR_IMG_PATH)
														: require('@/assets/img/tx.png')
												" alt="" />
											</div>
											<div class="personal">
												<div class="name flex1">
													<div>
														{{resumeItem.peR_NAME
																	? utils.formatName(resumeItem.peR_NAME)
																	: resumeItem.peR_ACCOUNT
																		? utils.formatName(resumeItem.peR_ACCOUNT)
																		: "神秘人"
														}}
													</div>
													<div class="tip">
														<span
															v-if="resumeItem.peR_GENDER">{{ resumeItem.peR_GENDER}}</span><span
															v-if="resumeItem.peR_GENDER">|</span>
														<span v-if="resumeItem.age">{{utils.getAge(resumeItem.peR_BIRTHDAY)}}岁</span><span
															v-if="resumeItem.age">|</span>
														<span
															v-if="resumeItem.peR_WORK_YEARS">{{resumeItem.peR_WORK_YEARS}}年经验</span><span
															v-if="resumeItem.peR_WORK_YEARS">|</span>
														<span>{{ resumeItem.per_edu }}</span><span
															v-if="resumeItem.per_edu">|</span>
														
													</div>
													<div style="font-size: 14px;color: rgb(137, 137, 137);">
														<span>更新时间:{{ resumeItem.peR_UPDATE_DATE.substring(0,11) }}</span>
													</div>
												</div>
												<div class="flex1" style="width:100%;">
													<div class="hot_move">
														<span>{{ resumeItem.workname }}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="resumeRight">
										<div class="talk1 flexc" @click="sendInvitation(resumeItem.peR_ID)">
											面试邀请
										</div>
										<div class="talk flexc" @click="
											talk('/resumedetail', {
												id: resumeItem.peR_ID,
												uid: user.coM_ID,
												zph: '',
											})
										">
											查看简历
										</div>
									</div>
								</div>
							</div>
							<div style="text-align: center; margin-bottom: 20px">
								<el-pagination hide-on-single-page @size-change="handleSizeChange"
									@current-change="handleCurrentChange" :current-page="form.pageNumber"
									:page-sizes="[10, 20, 30, 40]" :page-size="form.pageSize"
									layout="total, sizes, prev, pager, next, jumper" :total="total">
								</el-pagination>
							</div>
						</div>
						<el-empty style="background: #fff" v-if="PerJobList.length < 1" :image-size="200"></el-empty>
					</div>
					<div class="recent">
						<div class="recentBrowsing">
							<div class="title">
								<span>最近浏览</span>
							</div>
							<!-- <div class="occupation">
								<div class="occupation_company">
									<div>IOS开发工程师</div>
									<div style="color: rgba(137, 137, 137, 100);margin-top:15px;">金华六和机械有限公司</div>
								</div>
								<div class="salary">
									<span>12K-18K</span>
								</div>
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Getindexsearch,
		Addresume,
		Getposilist,
		Gethotcompanylist,
		addmessageinfo,
		addmessageroom,
	} from "@/api/home";
	import {
		favuser,
		cancelfavuser,
		searchperson,
		posilistall,
		ispaybycomid,
	} from "@/api/company";
	import {
		isnull,
		handleCofirm,
	} from "@/assets/js/index";
	import utils from "@/assets/js/utils";
	import global from "@/assets/js/globalconfig";
	export default {
		components: {

		},

		data() {
			return {
				defimgurl: global.baseimgurl,
				form: {
					searchKey: "",
					pageNumber: 1,
					pageSize: 7,
				},
				total: 0,
				cur: 0,
				PerJobList: [],
				curr: 0,

				educationlist: [], //学历
				isfieldchage: false,
				field1: 18,
				stateyear: "",
				field2: 80,
				endyear: "",
				key: "",
				user: {},
				searcList: [{
					clcik: "",
					name: "性别",
					data: [{
							name: "不限",
							value: ""
						},
						{
							name: "男",
							value: "男"
						},
						{
							name: "女",
							value: "女"
						},
					],
				}, {
					clcik: "",
					name: "行业",
					data: [{
						name: "不限",
						value: ""
					}],
				}, {
					clcik: "",
					name: "排序方式",
					data: [{
							name: "默认",
							value: ""
						},
						{
							name: "最新",
							value: "最新"
						},
					],
				}],

				idcompanylogin: false,
				ispaybycomiddata: 0,
			};
		},
		watch: {
			field1(value, o) {
				if (value < 18) {
					this.field1 = 18;
				} else if (value > 80) {
					this.field1 = 80;
				} else if (value > this.field2) {
					this.field1 = this.field2;
				} else {
					this.field1 = value;
				}
			},
			field2(value, o) {
				if (value < 18) {
					this.field2 = 18;
				} else if (value > 80) {
					this.field2 = 80;
				} else if (value < this.field1) {
					this.field2 = this.field1;
				} else {
					this.field2 = value;
				}
			},
		},
		mounted() {
			window.addEventListener("scroll", this.showbtn, true);
		},
		created() {
			if (localStorage.getItem("userinfo")) {
				this.user = JSON.parse(localStorage.getItem("userinfo"));
				if (this.user.coM_ID) {
					this.idcompanylogin = true;
					//this.ispaybycomid();
				}
			}
			this.GetList();
			this.Gethylists();
			//this.Getposilists();
		},
		methods: {
			sendInvitation(per_id) {
				let _this = this;
				let roomId = 0;
				// 为企业用户并且审核状态为已审（'1'）
				if (!isnull(_this.user) && _this.user.userType == 2 && _this.user.coM_SH == '1') {
					var str = "【系统消息】" + this.user.coM_NAME + "邀请您参加面试，如有意向请在系统中向该公司投递简历。"
					var par_room = {
						companyId: 0, // 系统消息,id为0
						PersonId: per_id,
					};
					addmessageroom(par_room).then((res) => {
						if (res.success) {
							roomId = res.data;
							this.addMessageInfo(roomId, str);
						} else {
							this.$message.error(res.msg);
							return;
						}
					});
				} else {
					this.$message.error("您不是会员企业，无法发送面试邀请！");
				}
			},
			addMessageInfo(roomId, str) {
				var par_info = {
					Memu: str, // 消息体
					RoomId: roomId,
					idType: 1, // 接收人idType
					PersonId: 0, // 发送人
					TypeId: 0, // 0是文本消息
				};
				addmessageinfo(par_info).then((res) => {
					if (res.success) {
						this.$message.success("成功发送面试邀请！");
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			ispaybycomid() {
				var _this = this;
				ispaybycomid({
					id: this.user.coM_ID
				}).then((res) => {
					_this.ispaybycomiddata = res.data;
				});
			},
			stringToDates(data) {
				return utils.stringToDate(data);
			},
			enter(i) {
				this.curr = i;
			},
			leave() {
				this.curr = null;
			},
			goPage(path) {
				this.$router.push(path);
			},
			SelectTap(i, j) {
				this.searcList[i].clcik = this.searcList[i].data[j].value;
				this.form.pageNumber = 1;
				this.GetList();
			},
			search() {
				this.form.pageNumber = 1;
				this.GetList();
			},
			yearselect() {
				var time = new Date();
				let year = time.getFullYear();
				this.stateyear = year - this.field1;

				this.endyear = year - this.field2;
				this.form.pageNumber = 1;
				this.GetList();
			},
			//人才简历列表
			GetList() {
				var _this = this;
				var par = {
					searchKey: _this.key,
					PageSize: _this.form.pageSize,
					PageNumber: _this.form.pageNumber,
					id: this.user.coM_ID,
					gender: this.searcList[0].clcik,
					field3: this.searcList[1].clcik,
					order: this.searcList[2].clcik,
					field2: this.stateyear,
					field1: this.endyear,
					showLoadType: ".position-tab-content",
				};
				searchperson(par).then((res) => {
					if (res.success) {
						res.data.rows.forEach((element) => {
							if (element.peR_LASTLOAD)
								element.hy = this.utils.handleDateHY(element.peR_LASTLOAD);
							if (element.peR_BIRTHDAY)
								element.age = this.getAge(element.peR_BIRTHDAY)
						});
						_this.total = res.data.total;
						_this.PerJobList = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},

			//计算年龄
			getAge(strAge) {
				var birArr = strAge.split("-");
				var birYear = birArr[0];
				var birMonth = birArr[1];
				var birDay = birArr[2];

				d = new Date();
				var nowYear = d.getFullYear();
				var nowMonth = d.getMonth() + 1; //记得加1
				var nowDay = d.getDate();
				var returnAge;

				if (birArr == null) {
					return false
				};
				var d = new Date(birYear, birMonth - 1, birDay);
				if (d.getFullYear() == birYear && (d.getMonth() + 1) == birMonth && d.getDate() == birDay) {
					if (nowYear == birYear) {
						returnAge = 0; // 
					} else {
						var ageDiff = nowYear - birYear; // 
						if (ageDiff > 0) {
							if (nowMonth == birMonth) {
								var dayDiff = nowDay - birDay; // 
								if (dayDiff < 0) {
									returnAge = ageDiff - 1;
								} else {
									returnAge = ageDiff;
								}
							} else {
								var monthDiff = nowMonth - birMonth; // 
								if (monthDiff < 0) {
									returnAge = ageDiff - 1;
								} else {
									returnAge = ageDiff;
								}
							}
						} else {
							return "出生日期晚于今天，数据有误"; //返回-1 表示出生日期输入错误 晚于今天
						}
					}
					return returnAge;
				} else {
					return ("输入的日期格式错误！");
				}
			},

			//人才收藏
			favuser(id) {
				var _this = this;
				var par = {
					id: this.user.coM_ID,
					uid: id,
				};
				favuser(par).then((res) => {
					if (res.success) {
						this.GetList();

						_this.$message.success("简历已收藏!");
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//取消人才收藏
			cancelfavuser(id) {
				var _this = this;
				handleCofirm('确认取消收藏该简历吗？', 'warning', ).then(res => {
					var par = {
						id: this.user.coM_ID,
						uid: id,
					};
					cancelfavuser(par).then((res) => {
						if (res.success) {
							this.GetList();

							_this.$message.success("已取消收藏!");
						} else {
							_this.$message.error(res.msg);
						}
					});
				}).catch(err => {
					//console.log('err', err) // cancel
				})
			},
			handleSizeChange(val) {
				this.form.pageNumber = 1;
				this.form.pageSize = val;
				this.GetList();
				this.backtop();
			},
			handleCurrentChange(val) {
				this.form.pageNumber = val;
				this.GetList();
				this.backtop();
			},
			setsalary(a, b) {
				var data = [];
				if (!isnull(a)) {
					if (a < 100 && a > 0) {
						data.push(a + "k");
					} else if (a >= 1000) {
						data.push(a / 1000 + "k");
					} else {
						data.push(a);
					}
				}
				if (!isnull(b)) {
					if (b < 100 && b > 0) {
						data.push(b + "k");
					} else if (b >= 1000) {
						data.push(b / 1000 + "k");
					} else {
						data.push(b);
					}
				}
				if (data.length === 0) {
					return "面议";
				} else {
					return data.join("-");
				}
			},

			backtop() {
				var timer = setInterval(function() {
					let osTop =
						document.documentElement.scrollTop || document.body.scrollTop;
					let ispeed = Math.floor(-osTop / 5);
					document.documentElement.scrollTop = document.body.scrollTop =
						osTop + ispeed;
					this.isTop = true;
					if (osTop === 0) {
						clearInterval(timer);
					}
				}, 30);
			},

			talk(url, data, type = 2) {
				this.resolvewin(url, data, type);
			},
			Getposilists() {
				var _this = this;
				Getposilist({
					types: "学历"
				}).then((res) => {
					if (res.success) {
						_this.educationlist = res.data.rows;
						res.data.rows.forEach((element) => {
							if (element.cName != "不限") {
								this.searcList[1].data.push({
									name: element.cName,
									value: element.cCode,
								});
							}
						});
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			Gethylists() {
				var _this = this;
				posilistall({
					types: "行业"
				}).then((res) => {
					if (res.success) {
						_this.educationlist = res.data.rows;
						res.data.rows.forEach((element) => {
							if (element.cName != "不限" && element.cName != "劳务协作" && element.cName !=
								"余缺调剂") {
								this.searcList[1].data.push({
									name: element.cName,
									value: element.cCode,
								});
							}
						});
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			setOFEDUREQ(data) {
				return isnull(data) ? "不限" : data;
			},
			clearsel() {
				this.form = {
					searchKey: "",
					pageNumber: 1,
					pageSize: 10,
				};
				this.GetList();
			},

			salary(data) {
				if (data.indexOf("-") == -1) {
					return "面议";
				} else {
					return data;
				}
			},

			setuserlogo(url) {
				var data = url.indexOf("http");
				return isnull(url) ?
					require("@/assets/img/logos.png") :
					data < 0 ?
					this.defimgurl + url :
					url;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.el-input {
		width: auto
	}

	.job-position {
		position: relative;
		width: 1200px;
		margin: 15px auto;

		.position-content {
			.position-tab-title {
				background: #fff;
				margin-bottom: 20px;
				padding: 20px 20px 1px 20px;

				.position-tab-a {
					display: flex;
					font-size: 14px;
					margin-bottom: 20px;

					.title {
						font-weight: bold;
						color: #333;
						padding: 5px 10px;
						min-width: 90px;
					}

					.info {
						span {
							margin: 0 20px;
						}

						.info-a {
							cursor: pointer;
							padding: 5px 10px;
							margin-right: 10px;
						}

						.info-Select {
							color: #fff;
							background-color: #2778f8;
							border-radius: 4px;
						}
					}

					.el-dropdown-link {
						cursor: pointer;
					}
				}


			}

			.center {
				display: flex;

				.position-tab {
					position: relative;
					// display: flex;
					align-items: stretch;
					width: 70%;

					.sort_order {
						width: 100%;
						height: 28px;
						background: #fff;
						margin-bottom: 20px;
						display: flex;
						padding-top: 10px;
						padding-bottom: 10px;

						//   box-sizing: border-box;
						.position-tab-a {
							display: flex;
							font-size: 14px;
							margin-bottom: 20px;
							margin-left: 20px;

							.title {
								font-weight: bold;
								color: #333;
								padding: 5px 10px;
								min-width: 90px;
							}

							.info {
								span {
									margin: 0 20px;
								}

								.info-a {
									cursor: pointer;
									padding: 5px 10px;
									margin-right: 10px;
								}

								.info-Select {
									color: #fff;
									background-color: #2778f8;
									border-radius: 4px;
								}
							}

							.el-dropdown-link {
								cursor: pointer;
							}
						}

						.position-tab-a1 {
							display: flex;
							font-size: 14px;
							// margin-bottom: 20px;
							margin-left: 97px;
							margin-top: 5px;

							.title {
								font-weight: bold;
								color: #333;
								// padding: 5px 10px;
								// min-width: 90px;
							}


						}
					}

					.position-tab-content {

						width: 100%;
						background: #fff;
						padding-top: 10px;

						.resume {
							width: 95%;
							padding: 20px;
							box-sizing: border-box;
							background: white;
							margin-bottom: 10px;
							cursor: pointer;
							margin-left: 2.5%;

							border: 1px solid #eee;

							.resumeLeft {
								width: 65%;

								.left {
									width: 100%;
									display: flex;
									// border-right: 1px solid #edf1f5;

									.pic {
										width: 85px;
										height: 85px;
										border-radius: 50%;
										overflow: hidden;
										margin-right: 20px;

										img {
											width: 100%;
										}
									}

									.personal {
										width: 100%;
										display: flex;
										flex-direction: column;
										justify-content: center;

										.name {
											color: #000;
											font-weight: bold;
											font-size: 16px;
											margin-bottom: 10px;

											.nv {
												color: rgb(220, 0, 29);
												margin-left: 10px;
											}

											.nan {
												margin-left: 10px;
											}

											.brisk {
												font-size: 14px;
												color: #666;
												font-weight: normal;
												margin-left: 15px;
											}

											.tip {
												margin-left: 13px;
												font-size: 14px;
												color: rgb(137, 137, 137);

												span {
													margin-left: 10px;
												}
											}
										}

										.salary {
											margin-right: 20px;
											color: #ff7630;
											font-size: 16px;
										}

										.company_profile {
											span {
												margin-right: 10px;
											}
										}

										.hot_move {
											color: #898989;
											font-size: 14px;
											font-family: SourceHanSansSC-regular;
											margin-top: 10px;

											span {
												margin-right: 30px;
											}

											span:first-child {
												color: #898989;

											}
										}

										.resumeFactor {
											span {
												margin: 0 5px;
												font-size: 16px;
												font-weight: bold;
											}
										}
									}
								}

								.right {
									width: 40%;
									margin-left: 20px;

									.state {
										color: #666;
										font-size: 14px;

										.top {
											margin-bottom: 15px;
										}
									}
								}
							}

							.resumeRight {
								width: 35%;
								font-size: 14px;
								display: flex;

								.follow {
									width: 80px;
									height: 30px;
									background: #0096ff;
									color: #fff;
									text-align: center;
									line-height: 35px;
									margin-bottom: 12px;
								}

								span {
									width: 80px;
									height: 32px;
									color: #898989;
									font-size: 14px;
									text-align: right;
									font-family: SourceHanSansSC-regular;
									//    background: red;
									margin-left: 8px;


								}

								.talk1 {

									margin-bottom: 10px;
									margin-left: 10%;
									width: 98px;
									height: 30px;
									border-radius: 4px;
									// background-color: rgba(0, 123, 255, 100);
									border: 1px solid rgba(0, 123, 255, 100);
									color: rgba(0, 123, 255, 100);
									font-size: 14px;
									text-align: center;
									font-family: Microsoft Yahei;
									display: flex;
									justify-content: center;

									img {
										width: 22px;
										height: 22px;
										margin-right: 5px;
									}
								}

								.talk {

									margin-bottom: 10px;
									margin-left: 10%;
									width: 98px;
									height: 30px;
									border-radius: 4px;
									background-color: rgba(0, 123, 255, 100);
									color: rgba(255, 255, 255, 100);
									font-size: 14px;
									text-align: center;
									font-family: Microsoft Yahei;
									display: flex;
									justify-content: center;

									img {
										width: 22px;
										height: 22px;
										margin-right: 5px;
									}
								}

								.collect {
									color: #276bf2;

									i {
										margin-right: 5px;
									}

									.iconoff {
										font-size: 24px;
									}

									.iconon {
										font-size: 28px;
									}
								}

								.talk:hover,
								.collect:hover {
									cursor: pointer;
								}
							}
						}

						.resume:hover {
							box-shadow: 0 0 6px rgba(39, 107, 242, 0.3);
						}
					}

					.position-other {
						width: 310px;
						margin-left: 12px;

						.edit-resume {
							padding: 16px 24px;
							background: #fff;
							margin-bottom: 12px;

							a {
								color: #66b1ff;
								padding: 12px;
								background: #fff;
								border: 1px solid #66b1ff;
								display: block;
								text-decoration: none;
							}
						}

						.other-ad {
							margin-bottom: 12px;
							display: flex;

							img {
								width: 100%;
							}
						}

						.other-copmpany {
							background: #fff;
							padding: 16px;

							.other-company-title {
								font-size: 18px;
								color: #333;
								padding-bottom: 16px;
								margin-bottom: 16px;
								text-align: left;
								border-bottom: 1px solid #eee;
							}

							.other-company-cell {
								display: flex;
								flex-wrap: wrap;
								justify-content: center;

								.company-item {
									width: 275px;
									padding: 12px 0;
									background: #f2f2f2;
									margin-bottom: 16px;
									position: relative;
									overflow: hidden;
									cursor: pointer;

									.company-logo {
										height: 50px;
										text-align: center;

										img {
											height: 50px;
										}
									}

									.company-name {
										font-size: 14px;
										line-height: 32px;
										color: #333;
										text-align: center;
										width: 70%;
										margin: 0 auto;
										overflow: hidden;
										text-overflow: ellipsis;
										white-space: nowrap;
									}

									.company-num {
										padding: 8px 0px;
										border-radius: 2px;
										border: 1px solid #eee;
										font-size: 12px;
										background: #fff;
										width: 180px;
										margin: 0px auto;
										text-align: center;

										b {
											color: #ff0000;
										}
									}
								}

								.company-item:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
				}


				.recent {
					width: 35%;
					margin-left: 2%;

					.recentBrowsing {
						width: 100%;
						// height: 455px;
						background-color: #fff;

						.title {
							color: rgba(16, 16, 16, 100);
							font-size: 16px;
							width: 97%;
							height: 50px;
							border-bottom: 1px solid #eee;
							margin-left: 3%;
							line-height: 50px;

							span {
								margin-left: 14px;
							}
						}

						.occupation {
							color: rgba(16, 16, 16, 100);
							font-size: 14px;
							width: 94%;
							height: 100px;
							border-bottom: 1px solid #eee;
							margin-left: 3%;
							margin-right: 3%;
							display: flex;
							align-items: center;
							justify-content: space-between;

							.occupation_company {
								margin-left: 15px;
							}

							.salary {
								margin-right: 15px;
								color: #ff7630;
								font-size: 16px;

							}

						}
					}

					.advertisement {
						width: 100%;
						height: 252px;
						background: #0096FF;
						margin-top: 16px;
					}

				}
			}

		}
	}
</style>
